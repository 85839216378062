import React from 'react'
import Layout from '../components/layout'

const MusicPage = () => (
  <Layout fullwidth={false}>
    <section className='section'>
      <div className='container content'>
          <div className='columns'>
              <iframe
              title="bandcamp"
                style={{ border: 0, width: '100%', height: '120px' }}
                src="https://bandcamp.com/EmbeddedPlayer/track=3704857483/size=large/bgcol=ffffff/linkcol=de270f/tracklist=false/artwork=small/transparent=true/" seamless><a href="https://thestagequebec.bandcamp.com/track/s-i-t-s">S.I.T.S. by The Stage</a></iframe>
          </div>
      </div>
    </section>
  </Layout>
)

export default MusicPage
